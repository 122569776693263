import axios from '@/axios-client.js';
import store from '../store';

const featureAPI = {
  async getFeaturesBbox(project_slug, queryString) {
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    const response = await axios.get(
      `${baseUrl}projects/${project_slug}/feature-bbox/${queryString ? '?' + queryString : ''}`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      const bbox = response.data;
      return [
        [bbox.minLat, bbox.minLon],
        [bbox.maxLat, bbox.maxLon],
      ];
    } else {
      return null;
    }
  },

  async getProjectFeature(project_slug, feature_id) {
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    const response = await axios.get(
      `${baseUrl}v2/features/${feature_id}/?project__slug=${project_slug}`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getPaginatedFeatures(url) {
    // Cancel any ongoing search request.
    store.dispatch('CANCEL_CURRENT_SEARCH_REQUEST');
    // Prepare the cancel token for the new request and store it.
    const cancelToken = axios.CancelToken.source();
    store.commit('SET_CANCELLABLE_SEARCH_REQUEST', cancelToken);
    const response = await axios.get(url, { cancelToken: cancelToken.token });
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getFeatureEvents(featureId) {
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    const response = await axios.get(
      `${baseUrl}features/${featureId}/events/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getFeatureAttachments(featureId) {
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    const response = await axios.get(
      `${baseUrl}features/${featureId}/attachments/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getFeatureLinks(featureId) {
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    const response = await axios.get(
      `${baseUrl}features/${featureId}/feature-links/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getFeaturesBlob(url) {
    const response = await axios
      .get(url, { responseType: 'blob' });
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async postOrPutFeature({ method, feature_id, feature_type__slug, project__slug, data }) {
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    let url = `${baseUrl}v2/features/`;
    if (method === 'PUT') {
      url += `${feature_id}/?
      feature_type__slug=${feature_type__slug}
      &project__slug=${project__slug}`;
    }
    
    const response = await axios({
      url,
      method,
      data,
    });
    if ((response.status === 200 || response.status === 201) && response.data) {
      return response;
    } else {
      return null;
    }
  },

  async updateFeature({ feature_id, feature_type__slug, project__slug, newStatus }) {
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    const url = `${baseUrl}v2/features/${feature_id}/?feature_type__slug=${feature_type__slug}&project__slug=${project__slug}`;

    const response = await axios({
      url,
      method: 'PATCH',
      data: { id: feature_id, status: newStatus, feature_type: feature_type__slug }
    });
    if (response.status === 200 && response.data) {
      return response;
    } else {
      return null;
    }
  },

  async projectFeatureBulkUpdateStatus(projecSlug, queryString, newStatus) {
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    const url = `${baseUrl}projects/${projecSlug}/feature-bulk-modify/?${queryString}`;

    const response = await axios({
      url,
      method: 'PUT',
      data: { status: newStatus }
    });
    if (response.status === 200 && response.data) {
      return response;
    } else {
      return null;
    }
  },

  async projectFeatureBulkDelete(projecSlug, queryString) {
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    const url = `${baseUrl}projects/${projecSlug}/feature-bulk-modify/?${queryString}`;

    const response = await axios({
      url,
      method: 'DELETE'
    });
    if (response.status === 200 && response.data) {
      return response;
    } else {
      return null;
    }
  },

  async postComment({ featureId, comment }) {
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    const response = await axios.post(
      `${baseUrl}features/${featureId}/comments/`, { comment }
    );
    if (
      response.status === 201 &&
      response.data
    ) {
      return response;
    } else {
      return null;
    }
  },

  async postCommentAttachment({ featureId, file, fileName, title, isKeyDocument, commentId }) {
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    const formdata = new FormData();
    formdata.append('file', file, fileName);
    formdata.append('title', title);
    formdata.append('is_key_document', isKeyDocument);

    const response = await axios.put(
      `${baseUrl}features/${featureId}/comments/${commentId}/upload-file/`, formdata
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response;
    } else {
      return null;
    }
  },
      
  async getFeaturePosition(projectSlug, featureId, query) {
    const searchParams = new URLSearchParams(query);
    const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;
    const response = await axios.get(`${baseUrl}projects/${projectSlug}/feature/${featureId}/position-in-list/?${searchParams.toString()}`);
    if (response && response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return response.statusText;
    }
    return null;
  },
};

export default featureAPI;
