var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$route.name,attrs:{"id":"app-header"}},[_c('div',{staticClass:"menu container"},[_c('div',{staticClass:"ui inverted icon menu"},[_c(_vm.isSharedProject ? 'span' : 'router-link',{tag:"router-link",class:['header item', {disable: _vm.isSharedProject}],attrs:{"to":_vm.isSharedProject ? '' : '/'}},[_c('img',{staticClass:"ui right spaced image",attrs:{"alt":"Logo de l'application","src":_vm.logo}}),_c('span',{staticClass:"desktop"},[_vm._v(" "+_vm._s(_vm.APPLICATION_NAME)+" ")])]),(_vm.width <= 560 || (_vm.width > 560 && _vm.project))?_c('div',{class:['ui dropdown item', { 'active visible': _vm.menuIsOpen }],attrs:{"id":"menu-dropdown"},on:{"click":function($event){_vm.menuIsOpen = !_vm.menuIsOpen}}},[(!_vm.isOnline)?_c('div',{staticClass:"crossed-out mobile"},[_c('i',{staticClass:"wifi icon",attrs:{"aria-hidden":"true"}})]):_vm._e(),_c('span',{staticClass:"expand-center"},[(_vm.project)?_c('span',[_vm._v(" Projet : "+_vm._s(_vm.project.title)+" ")]):_vm._e()]),_c('i',{staticClass:"dropdown icon",attrs:{"aria-hidden":"true"}}),_c('div',{class:[
            'menu dropdown-list transition',
            { 'visible': _vm.menuIsOpen },
          ],staticStyle:{"z-index":"401"}},[(_vm.project)?_c('router-link',{staticClass:"item",attrs:{"to":{
              name: 'project_detail',
              params: { slug: _vm.project.slug },
            }}},[_c('i',{staticClass:"home icon",attrs:{"aria-hidden":"true"}}),_vm._v("Accueil ")]):_vm._e(),(_vm.project)?_c('router-link',{staticClass:"item",attrs:{"to":{
              name: 'liste-signalements',
              params: { slug: _vm.project.slug },
            }}},[_c('i',{staticClass:"list icon",attrs:{"aria-hidden":"true"}}),_vm._v("Liste & Carte ")]):_vm._e(),(
              _vm.project && _vm.isOnline && _vm.hasAdminRights)?_c('router-link',{staticClass:"item",attrs:{"to":{
              name: 'project_mapping',
              params: { slug: _vm.project.slug },
            }}},[_c('i',{staticClass:"map icon",attrs:{"aria-hidden":"true"}}),_vm._v("Fonds cartographiques ")]):_vm._e(),(
              _vm.project && _vm.isOnline && _vm.hasAdminRights)?_c('router-link',{staticClass:"item",attrs:{"to":{
              name: 'project_members',
              params: { slug: _vm.project.slug },
            }}},[_c('i',{staticClass:"users icon",attrs:{"aria-hidden":"true"}}),_vm._v("Membres ")]):_vm._e(),_c('div',{staticClass:"mobile"},[(_vm.user)?_c(_vm.isOnline ? 'router-link' : 'span',{tag:"router-link",staticClass:"item",attrs:{"to":{
                name: 'my_account',
                params: { slug: _vm.isSharedProject && _vm.$route.params.slug ? _vm.$route.params.slug : null }
              }}},[_vm._v(" "+_vm._s(_vm.userFullname || _vm.user.username || "Utilisateur inconnu")+" ")]):_vm._e(),(_vm.USER_LEVEL_PROJECTS && _vm.project)?_c('div',{staticClass:"item ui label vertical no-hover"},[_vm._v(" "+_vm._s(_vm.user && _vm.user.is_superuser ? 'Administrateur' : _vm.USER_LEVEL_PROJECTS[_vm.project.slug])+" "),_c('br')]):_vm._e(),(_vm.user && _vm.user.is_administrator)?_c('div',{staticClass:"item ui label vertical no-hover"},[_vm._v(" Gestionnaire métier ")]):_vm._e(),(!_vm.DISABLE_LOGIN_BUTTON)?_c('div',[(_vm.user)?_c('a',{staticClass:"item",on:{"click":_vm.logout}},[_c('i',{staticClass:"ui logout icon",attrs:{"aria-hidden":"true"}})]):(!_vm.user && !_vm.SSO_LOGIN_URL)?_c('router-link',{staticClass:"item",attrs:{"to":{ name : 'login' }}},[_vm._v(" Se connecter ")]):_c('a',{staticClass:"item",attrs:{"href":_vm.SSO_LOGIN_URL,"target":"_self"}},[_vm._v("Se connecter")])],1):_vm._e()],1)],1)]):_vm._e(),_c('div',{staticClass:"desktop flex push-right-desktop item title abstract"},[_c('span',[_vm._v(" "+_vm._s(_vm.APPLICATION_ABSTRACT)+" ")])]),_c('div',{staticClass:"desktop flex push-right-desktop"},[(!_vm.isOnline)?_c('div',{staticClass:"item network-icon"},[_vm._m(0)]):_vm._e(),(_vm.user)?_c(_vm.isOnline ? 'router-link' : 'span',{tag:"router-link",staticClass:"item",attrs:{"to":{
            name: 'my_account',
            params: { slug: _vm.isSharedProject && _vm.$route.params.slug ? _vm.$route.params.slug : null }
          }}},[_vm._v(" "+_vm._s(_vm.userFullname || _vm.user.username || "Utilisateur inconnu")+" ")]):_vm._e(),(_vm.USER_LEVEL_PROJECTS && _vm.project)?_c('div',{staticClass:"item ui label vertical no-hover"},[_vm._v(" "+_vm._s(_vm.user && _vm.user.is_superuser ? 'Administrateur' : _vm.USER_LEVEL_PROJECTS[_vm.project.slug])+" "),_c('br')]):_vm._e(),(_vm.user && _vm.user.is_administrator)?_c('div',{staticClass:"item ui label vertical no-hover"},[_vm._v(" Gestionnaire métier ")]):_vm._e(),(!_vm.DISABLE_LOGIN_BUTTON)?_c('div',[(_vm.user)?_c('a',{staticClass:"item log-item",on:{"click":_vm.logout}},[_c('i',{staticClass:"ui logout icon",attrs:{"aria-hidden":"true"}})]):(!_vm.user && !_vm.SSO_LOGIN_URL)?_c('router-link',{staticClass:"item log-item",attrs:{"to":{ name : 'login' }}},[_vm._v(" Se Connecter ")]):_c('a',{staticClass:"item log-item",attrs:{"href":_vm.SSO_LOGIN_URL,"target":"_self"}},[_vm._v("Se connecter")])],1):_vm._e()],1)],1),_c('MessageInfoList')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{attrs:{"data-tooltip":"Vous êtes hors-ligne,\n              vos changements pourront être envoyés au serveur au retour de la connexion","data-position":"bottom right"}},[_c('div',{staticClass:"crossed-out"},[_c('i',{staticClass:"wifi icon",attrs:{"aria-hidden":"true"}})])])
}]

export { render, staticRenderFns }